import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Offcanvas, Button } from 'react-bootstrap';
import BaseMapSetting from '../map/basemapsetting';
import LayerSetting from '../map/layersetting';
import SelectedLayerList from '../map/selectedlayerlist';
import StudySetting from '../map/studysetting';
import '../App.css';

const MapSetting = ({ mode, basemap, selectedBasemap, setSelectedBasemap, layer, selectedLayer, setSelectedLayer, category }) => {
    
    const [show, setShow] = useState(true);
    const toggleOffcanvas = () => setShow(!show);

    return (
        <>
            <Button
                variant="dark"
                onClick={toggleOffcanvas}
                style={{
                    position: "fixed",
                    top: "55px",
                    left: show ? "300px" : "0px", // Offcanvas の状態に応じた位置調整
                    zIndex: 1001, // Offcanvas より高い
                    backgroundColor: "rgb(46, 46, 46)",
                    color: "white",
                    border: "none",
                    borderRadius: "0 .25rem .25rem 0",
                    padding: ".3rem",
                    transition: "left 0.3s ease-in-out"
                }}
            >
                {show ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                    <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>}
            </Button>
            <Offcanvas id="mapsetting" show={show} scroll={true} backdrop={false}>
                <BaseMapSetting
                    basemap={basemap}
                    selectedBasemap={selectedBasemap}
                    setSelectedBasemap={setSelectedBasemap}

                />
                <LayerSetting
                    mode={mode}
                    layer={layer}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    category={category}
                />
                <SelectedLayerList
                    mode={mode}
                    layer={layer}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    category={category}
                />
            </Offcanvas>
        </>
    );
};

export default MapSetting;