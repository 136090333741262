import React from 'react';
import Header from "./components/header";
import { Table } from 'react-bootstrap';

function log() {
  return (
    <>
      <Header />
      <Table striped responsive>
        <thead className="table-dark text-center">
          <tr>
            <th>年月日</th>
            <th>変更箇所</th>
            <th>変更内容</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">2025/1/20</th>
            <td>新規公開</td>
            <td>卒業論文の提出とともに新規公開。</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default log;