import React from 'react';
import { ListGroup, Form, Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const SelectedLayerList = ({ mode, layer, selectedLayer, setSelectedLayer }) => {
    // レイヤーの状態を更新する汎用関数
    const updateLayer = (layerId, updates) => {
        setSelectedLayer((prevLayers) =>
            prevLayers.map((layer) =>
                layer.id === layerId ? { ...layer, ...updates } : layer
            )
        );
    };

    // チェックボックスの変更処理
    const handleLayerToggle = (e) => {
        const targetId = e.target.value;
        setSelectedLayer((prevLayers) =>
            prevLayers.some((layer) => layer.id === targetId)
                ? prevLayers.filter((layer) => layer.id !== targetId)
                : [...prevLayers, layer.find((l) => l.id === targetId)]
        );
    };

    // レイヤー順序を変更する処理
    const handleMoveLayer = (layerId, direction) => {
        setSelectedLayer((prevLayers) => {
            const index = prevLayers.findIndex((layer) => layer.id === layerId);
            if (index === -1) return prevLayers;

            const targetIndex = index + direction;
            if (targetIndex < 0 || targetIndex >= prevLayers.length) return prevLayers;

            const newLayers = [...prevLayers];
            [newLayers[index], newLayers[targetIndex]] = [newLayers[targetIndex], newLayers[index]];
            return newLayers;
        });
    };

    return (
        <div id="selectedlayer">
            <div className="title">選択中のレイヤ</div>
            <div id="selectedlayerbody">
                {selectedLayer.length > 0 ? (
                    <ListGroup variant="flush">
                        {selectedLayer.map((layer) => (
                            <ListGroup.Item key={layer.id}>
                                <div className="layer-row">
                                    <Col xs={9} className="layer-checkbox">
                                        <Form.Check
                                            type="checkbox"
                                            id={`${layer.id}_checkbox`}
                                            label={layer.name}
                                            value={layer.id}
                                            onChange={handleLayerToggle}
                                            checked={selectedLayer.some((slayer) => slayer.id === layer.id)}
                                        />
                                    </Col>
                                    <div className="layer-buttons">
                                        {/* 設定ボタン */}
                                        <Col xs="auto">
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                rootClose
                                                overlay={
                                                    <Popover id={`settings-${layer.id}`}>
                                                        <Popover.Header as="h3">表示設定</Popover.Header>
                                                        <Popover.Body>
                                                            {/* 透過度 */}
                                                            <Form.Group controlId="permeabilityRange">
                                                                <Form.Label>透過度</Form.Label>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="me-2">0%</span>
                                                                    <Form.Range
                                                                        value={layer.permeability}
                                                                        onChange={(e) =>
                                                                            updateLayer(layer.id, {
                                                                                permeability: parseFloat(e.target.value),
                                                                            })
                                                                        }
                                                                        min={0}
                                                                        max={1}
                                                                        step={0.01}
                                                                    />
                                                                    <span className="ms-2">100%</span>
                                                                </div>
                                                            </Form.Group>
                                                            {/* 乗算モード */}
                                                            <Form.Group controlId="blendModeSwitch">
                                                                <Form.Check
                                                                    type="switch"
                                                                    label="乗算モード"
                                                                    id={`blend-switch-${layer.id}`}
                                                                    checked={layer.blend}
                                                                    onChange={(e) =>
                                                                        updateLayer(layer.id, {
                                                                            blend: e.target.checked,
                                                                        })
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button variant="link" className="p-0">
                                                    <i className="bi bi-gear" />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        {/* 上下移動ボタン */}
                                        <Col xs="auto" className="d-flex flex-column align-items-center">
                                            <Button
                                                variant="link"
                                                className="p-0"
                                                onClick={() => handleMoveLayer(layer.id, -1)}
                                            >
                                                <i className="bi bi-caret-up-fill" />
                                            </Button>
                                            <Button
                                                variant="link"
                                                className="p-0"
                                                onClick={() => handleMoveLayer(layer.id, 1)}
                                            >
                                                <i className="bi bi-caret-down-fill" />
                                            </Button>
                                        </Col>
                                        {/* レイヤ情報ボタン */}
                                        <Col xs="auto" className="d-flex flex-column align-items-center">
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                rootClose
                                                overlay={
                                                    <Popover id={`note-${layer.id}`}>
                                                        <Popover.Header as="h3">レイヤ情報</Popover.Header>
                                                        <Popover.Body>{layer.note}</Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button variant="link" className="p-0">
                                                    <i className="bi bi-info-circle-fill" />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : (
                    "レイヤがありません"
                )}
            </div>
        </div>
    );
};

export default SelectedLayerList;