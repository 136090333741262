import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, ScaleControl, useMap } from 'react-leaflet';
import BaseMap from './basemap';
import Layer from './layer';

const MapStateHandler = ({ mode, selectedBasemap, selectedLayer }) => {
    const map = useMap();

    useEffect(() => {
        const handleMapChange = () => {
            const center = map.getCenter();
            const zoom = map.getZoom();
            const layerIds = selectedLayer.map(layer => layer.id).join(',');
            const newHash = `#${zoom}/${center.lat.toFixed(6)}/${center.lng.toFixed(6)}/&mode=${mode}&base=${selectedBasemap}&layer=${layerIds}`;
            window.history.replaceState(null, '', newHash);
        };

        map.on('moveend', handleMapChange);
        map.on('zoomend', handleMapChange);

        return () => {
            map.off('moveend', handleMapChange);
            map.off('zoomend', handleMapChange);
        };
    }, [map, mode, selectedBasemap, selectedLayer]);

    return null;
};

const MapView = ({ mode, setMode, basemap, selectedBasemap, setSelectedBasemap, layer, selectedLayer, setSelectedLayer, geojsonData }) => {
    const [zoom, setZoom] = useState(5);  // ズームレベル
    const [lat, setLat] = useState(34.686235);  // 緯度
    const [lng, setLng] = useState(135.520054);  // 経度
    const [initialized, setInitialized] = useState(false);
    const [hash, setHash] = useState(() => { if (localStorage.hasOwnProperty("hash")) { return localStorage.getItem('hash') } else { return "" } });
    const mapRef = useRef();


    useEffect(() => {
        const sHash = window.location.hash.slice(1);
        localStorage.setItem('hash', sHash);
        const [zoomParam, latParam, lngParam, ...params] = sHash.split('/');
        const initialZoom = Number(zoomParam) || 5;
        const initialLat = Number(latParam) || 34.686235;
        const initialLng = Number(lngParam) || 135.520054;

        const queryParams = params.join('&').split('&').reduce((acc, param) => {
            const [key, value] = param.split('=');
            if (key) acc[key] = value;
            return acc;
        }, {});

        // 緯度、経度、ズームレベルの状態を更新
        setZoom(initialZoom);
        setLat(initialLat);
        setLng(initialLng);

        // モードの設定
        setMode(queryParams.mode || 'world');

        setInitialized(true);
    }, [setMode]);

    // 初期化が終わったら地図を表示
    if (!initialized) return null;

    return (
        <>
            <MapContainer
                id={"map"}
                center={[lat, lng]}
                zoom={zoom}
                preferCanvas={true}
                boxZoom={true}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                touchZoom={true}
                ref={mapRef}
                whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
            >
                <BaseMap
                    basemap={basemap}
                    selectedBasemap={selectedBasemap}
                    setSelectedBasemap={setSelectedBasemap}
                />
                <Layer
                    layer={layer}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    geojsonData={geojsonData}
                />
                <ScaleControl metric={true} imperial={false} position="bottomright" />
                <MapStateHandler
                    mode={mode}
                    selectedBasemap={selectedBasemap}
                    selectedLayer={selectedLayer}
                />
            </MapContainer>

            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    zIndex: 1001,
                }}
            >
                <img
                    src="./crosshairs.png"
                    alt="Crosshair"
                    style={{ width: '36px', height: '36px' }}
                />
            </div>
        </>
    );
};

export default MapView;