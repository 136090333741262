import '../App.css';
import React from 'react';

const BaseMapSetting = ({ basemap, selectedBasemap, setSelectedBasemap }) => {

    return (
        <div id="basemapsetting">
            <div className="title">ベースマップ</div>
            <div id="basemapbody">
                <div className="basemapcategory">
                    <div className="basemapcategorytitle">地理院地図</div>
                    <div className="basemapcategorybody">
                        <label htmlFor="gsistd" className="basemap">
                            <input type="radio" value={"0"} id="gsistd" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "0"}></input>
                            <span className="basemap-text">標準地図</span>
                        </label>
                        <label htmlFor="gsipale" className="basemap">
                            <input type="radio" value={"1"} id="gsipale" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "1"}></input>
                            <span className="basemap-text">淡色地図</span>
                        </label>
                        <label htmlFor="gsiblank" className="basemap">
                            <input type="radio" value={"2"} id="gsiblank" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "2"}></input>
                            <span className="basemap-text">白地図</span>
                        </label>
                        <label htmlFor="gsiphoto" className="basemap">
                            <input type="radio" value={"3"} id="gsiphoto" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "3"}></input>
                            <span className="basemap-text">写真</span>
                        </label>
                    </div>
                </div>
                <div className="basemapcategory">
                    <div className="basemapcategorytitle">OpenStreetMap</div>
                    <div className="basemapcategorybody">
                        <label htmlFor="osm" className="basemap">
                            <input type="radio" value={"4"} id="osm" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "4"}></input>
                            <span className="basemap-text">通常</span>
                        </label>
                        <label htmlFor="osmmbj" className="basemap">
                            <input type="radio" value={"5"} id="osmmbj" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "5"}></input>
                            <span className="basemap-text">Maptiler-Basic-JA</span>
                        </label>
                    </div>
                </div>
                <div className="basemapcategory">
                    <div className="basemapcategorytitle">デジタル地図帳 for School</div>
                    <div className="basemapcategorybody">
                        <label htmlFor="heibon" className="basemap">
                            <input type="radio" value={"6"} id="heibon" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "6"}></input>
                            <span className="basemap-text">一般図</span>
                        </label>
                    </div>
                </div>
                <div className="basemapcategory">
                    <div className="basemapcategorytitle">MIERUNE</div>
                    <div className="basemapcategorybody">
                        <label htmlFor="mierune" className="basemap">
                            <input type="radio" value={"7"} id="mierune" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "7"}></input>
                            <span className="basemap-text">MIERUNE</span>
                        </label>
                        <label htmlFor="mierunemono" className="basemap">
                            <input type="radio" value={"8"} id="mierunemono" name="basemap" onChange={(e) => setSelectedBasemap(e.target.value)} checked={selectedBasemap === "8"}></input>
                            <span className="basemap-text">MIERUNE MONO</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaseMapSetting;