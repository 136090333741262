import './App.css';
import React, { useState, useEffect } from 'react'
import Header from './components/header'
import MapSetting from './components/mapsetting';
import MapView from './map/mapview';
import basemap from './list/basemap.json';
import layer from './list/layer.json';
import category from './list/category.json';

function App() {

  const [selectedBasemap, setSelectedBasemap] = useState("0");
  const [selectedLayer, setSelectedLayer] = useState([]);
  const [mode, setMode] = useState("world")

  return (
    <>
      <Header
        mode={mode}
        setMode={setMode}
      />
      <MapSetting
        mode={mode}
        basemap={basemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        category={category}
      />
      <MapView
        mode={mode}
        setMode={setMode}
        basemap={basemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
      />
    </>
  );
}

export default App;