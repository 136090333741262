import React from 'react';
import { Accordion, ListGroup, Form, Badge, Col, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const LayerSetting = ({ mode, layer, selectedLayer, setSelectedLayer, category }) => {

    const layer_change = (e) => {
        if (selectedLayer.some(layer => layer.id === e.target.value)) {
            setSelectedLayer(
                selectedLayer.filter(layer => layer.id !== e.target.value)
            );
        } else {
            setSelectedLayer([...selectedLayer, layer.filter(layer => layer.id === e.target.value)].flat());
        }
    };

    const categorizedLayers = category.map((category) => {
        // category.typeが"layer"でない場合はnullを返してスキップ
        if (category.type !== "layer") return null;

        const categoryLayers = layer.filter((layer) => layer.category === category.id && layer.map === mode);
        return { ...category, layers: categoryLayers };
    }).filter(Boolean); // nullの値を除去

    return (
        <div id="layersetting">
            <div className="title">レイヤ</div>
            <div id="layerbody">
                <Accordion flush>
                    {categorizedLayers.map((category, index) => (
                        <Accordion.Item eventKey={index.toString()} key={category.id}>
                            <Accordion.Header as={'div'} className="w-100 d-flex justify-content-between align-items-center">
                                <p className="mb-0 flex-grow-1">{category.name}</p>
                                <Badge pill bg="secondary">{category.layers.length}</Badge>
                            </Accordion.Header>

                            {category.layers.length > 0 ? (
                                <Accordion.Body>
                                    <ListGroup>
                                        {category.layers.map((layer) => (
                                            <ListGroup.Item key={layer.id}>
                                                <div className="layer-row">
                                                    <Col xs={11} className="layer-checkbox">

                                                        <Form.Check type="checkbox" inline key={layer.id} id={layer.id} label={layer.name} value={layer.id} onChange={layer_change} checked={selectedLayer.some(slayer => slayer.id === layer.id)} />
                                                    </Col>
                                                    <div className="layer-buttons">
                                                        {/* レイヤ情報ボタン */}
                                                        <Col xs="auto" className="d-flex flex-column align-items-center">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                placement="right"
                                                                rootClose
                                                                overlay={
                                                                    <Popover id={`note-${layer.id}`}>
                                                                        <Popover.Header as="h3">レイヤ情報</Popover.Header>
                                                                        <Popover.Body>
                                                                            {layer.note}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Button variant="link" className="p-0">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                                                    </svg>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </ ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Accordion.Body>
                            ) : (
                                <Accordion.Body>レイヤがありません</Accordion.Body>
                            )}

                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    )
}

export default LayerSetting;