import '../App.css';
import 'leaflet/dist/leaflet.css';
import React from 'react'
import { TileLayer } from 'react-leaflet'

const BaseMap = ({basemap, selectedBasemap}) => {

    const attribution = basemap[selectedBasemap].attribution
    const url = basemap[selectedBasemap].url

    return (
          <TileLayer
            attribution={attribution}
            url={url}
          />
    )
}

export default BaseMap;