import '../App.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { TileLayer, Marker, CircleMarker, Popup, FeatureGroup, ImageOverlay, GeoJSON } from 'react-leaflet';
import Leaflet from "leaflet";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, LineController } from "chart.js";
import { Bar } from "react-chartjs-2";
import card_list from '../list/card_list.json';
import { fromArrayBuffer } from "geotiff";
import chroma from "chroma-js";

const Layer = ({ selectedLayer }) => {

  // ラスターレイヤの処理
  const rasters = selectedLayer
    .filter(slayer => slayer.type === "raster") // selectedLayer の中から "raster" タイプのみ抽出
    .map(slayer => (
      <TileLayer
        key={slayer.id}
        attribution={slayer.attribution || ""} // 必要に応じてデフォルト値を設定
        url={slayer.url || ""}
        className={slayer.blend ? "multiply-blend" : ""}
        opacity={slayer.permeability !== undefined ? 1 - slayer.permeability : 1} // 透過度に基づく不透明度を設定
      />
    ));

  // GeoTiffOverlay コンポーネントを作成
  const GeoTiffOverlay = ({ url }) => {
    const [imageDataUrl, setImageDataUrl] = useState(null);
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
      const loadGeoTiff = async () => {
        try {
          // GeoTIFF を読み込む
          const response = await fetch(url);
          const arrayBuffer = await response.arrayBuffer();
          const tiff = await fromArrayBuffer(arrayBuffer);
          const image = await tiff.getImage();

          const width = image.getWidth();
          const height = image.getHeight();
          const bbox = image.getBoundingBox(); // [minX, minY, maxX, maxY]
          const data = await image.readRasters();

          // GeoTIFF の範囲を設定
          const bounds = [
            [bbox[1], bbox[0]], // 南西 (minY, minX)
            [bbox[3], bbox[2]], // 北東 (maxY, maxX)
          ];
          setBounds(bounds);

          // Canvas に描画
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          const imageData = ctx.createImageData(width, height);
          const colorScale = chroma.scale(['blue', 'cyan', 'lime', 'yellow', 'orange', 'red']).domain([-10, 30]); // カラースケール
          // Raster データを Canvas に描画
          const raster = data[0]; // 最初のバンド
          for (let i = 0; i < raster.length; i++) {
            const value = raster[i];

            if (isNaN(value)) {
              // NaN の場合は透明にする
              imageData.data[i * 4] = 0; // 赤
              imageData.data[i * 4 + 1] = 0; // 緑
              imageData.data[i * 4 + 2] = 0; // 青
              imageData.data[i * 4 + 3] = 0; // アルファ (完全に透明)
            } else {
              // 有効な値の場合はカラースケールを適用
              const [r, g, b] = colorScale(value).rgb();
              imageData.data[i * 4] = r; // 赤
              imageData.data[i * 4 + 1] = g; // 緑
              imageData.data[i * 4 + 2] = b; // 青
              imageData.data[i * 4 + 3] = 255; // アルファ (不透明)
            }
          }
          ctx.putImageData(imageData, 0, 0);

          // Canvas を Data URL に変換して保存
          setImageDataUrl(canvas.toDataURL());
        } catch (error) {
          console.error("Error loading GeoTIFF:", error);
        }
      };

      loadGeoTiff();
    }, [url]);

    if (!imageDataUrl || !bounds) {
      return null; // データが読み込まれるまで何も返さない
    }

    return <ImageOverlay url={imageDataUrl} bounds={bounds} />;
  };
  // GeoTIFF レイヤの処理
  const geotiffs = selectedLayer
    .filter(slayer => slayer.type === "geotiff") // selectedLayer の中から "geotiff" タイプのみ抽出
    .map(slayer => (
      <GeoTiffOverlay key={slayer.id} url={slayer.url} />
    ));

  let climates = null; // climates を初期化
  let manhole_distributions = null; // manhole_distributions を初期化

  // climates の処理
  const [climateData, setClimateData] = useState(null);

  useEffect(() => {
    const matchingLayers = selectedLayer.filter(layer =>
      ["climate_world", "climate_japan", "climate_world_koppen", "climate_japan_koppen"].includes(layer.id)
    );

    if (matchingLayers.length > 0) {
      const loadGeoJSONs = async () => {
        try {
          const responses = await Promise.all(
            matchingLayers.map(layer => fetch(layer.url))
          );

          const data = await Promise.all(
            responses.map(response => {
              if (!response.ok) {
                throw new Error(`Failed to fetch GeoJSON data from ${response.url}`);
              }
              return response.json();
            })
          );

          // 複数のGeoJSONデータをまとめてセット
          setClimateData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSONs();
    }
  }, [selectedLayer]);

  // Chart.js の登録は条件外で行う
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController
  );

  // 雨温図のデータ作成関数とオプション設定は変わりません
  const createChartData = (temperature, precipitation) => ({
    labels: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    datasets: [
      {
        type: "line",
        label: "月平均気温(°C)",
        data: temperature,
        borderColor: "#ff6347",
        backgroundColor: "#ff6347",
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "月降水量(mm)",
        data: precipitation,
        backgroundColor: "#1e90ff",
        yAxisID: "y2",
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltip: {
      bodyFont: {
        family: 'BIZ UDPGothic'
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: 'BIZ UDPGothic'
          },
        },
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            family: 'BIZ UDPGothic'
          },
        }
      },
      y1: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "月平均気温(℃)",
          font: {
            family: 'BIZ UDPGothic'
          },
        },
        min: -30,
        max: 40,
        ticks: {
          font: {
            family: 'BIZ UDPGothic'
          },
        },
      },
      y2: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "月降水量(mm)",
          font: {
            family: 'BIZ UDPGothic'
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        min: 0,
        max: 800,
        ticks: {
          font: {
            family: 'BIZ UDPGothic'
          },
        },
      },
    },
  };


  let type = "" //乾燥季節型
  let arid_boundary = "" //乾燥限界値
  //ケッペンの気候区判定
  const koppen = (temperature, precipitation, latitude) => {

    const sum = function (arr) { //合計を求める関数
      return arr.reduce(function (prev, current, i, arr) {
        return prev + current;
      });
    };

    if (temperature.every(element => element === null)) {
      return "気温データ欠損のため，気候区判定不可"
    }
    else if (precipitation.every(element => element === null)) {
      return "降水量データ欠損のため，気候区判定不可"
    }

    const maxTemp = Math.max.apply(null, temperature); //最暖月平均気温
    const minTemp = Math.min.apply(null, temperature); //最寒月平均気温
    const aveTemp = sum(temperature) / 12; //年平均気温

    const sumPrec = sum(precipitation) //年降水量
    const maxPrec = Math.max.apply(null, precipitation); //最多雨月降水量
    const minPrec = Math.min.apply(null, precipitation); //最少雨月降水量
    const minPrecM = precipitation.indexOf(minPrec) + 1; //最多雨月

    type = "f" //乾燥季節型
    arid_boundary = 0 //乾燥限界値

    //乾燥季節型の判定
    if (latitude >= 0 && (minPrecM <= 3 || minPrecM >= 10) && minPrec * 10 < maxPrec) { //北半球で，最少雨月が冬にあり，最少雨月降水量の10倍が最多雨月降水量を下回る
      type = "w" //冬季乾燥型
      arid_boundary = 20 * (aveTemp + 14)
    }
    else if (latitude < 0 && (minPrecM >= 4 && minPrecM <= 10) && minPrec * 10 < maxPrec) { //南半球で，最少雨月が冬にあり，最少雨月降水量の10倍が最多雨月降水量を下回る
      type = "w" //冬季乾燥型
      arid_boundary = 20 * (aveTemp + 14)
    }
    else if (latitude >= 0 && (minPrecM >= 4 && minPrecM <= 10) && minPrec * 3 < maxPrec) { //北半球で，最少雨月が夏にあり，最少雨月降水量の3倍が最多雨月降水量を下回る
      type = "s" //夏季乾燥型
      arid_boundary = 20 * (aveTemp)
    }
    else if (latitude < 0 && (minPrecM <= 3 || minPrecM >= 10) && minPrec * 3 < maxPrec) { //南半球で，最少雨月が夏にあり，最少雨月降水量の3倍が最多雨月降水量を下回る
      type = "s" //夏季乾燥型
      arid_boundary = 20 * (aveTemp)
    }
    else {
      type = "f" //年中湿潤型
      arid_boundary = 20 * (aveTemp + 7)
    };


    if (maxTemp < 10) { //寒帯である(最暖月平均気温が10℃未満)
      if (maxTemp < 0) { //最暖月平均気温が0℃未満
        return "氷雪気候(EF)"
      }
      else {
        return "ツンドラ気候(ET)"
      }
    }
    else if (sumPrec < arid_boundary) { //乾燥帯である(年降水量が乾燥限界値を下回る)
      if (sumPrec >= 0.5 * arid_boundary) { //年降水量が乾燥限界値の1/2以上である
        return "ステップ気候(BS)"
      }
      else {
        return "砂漠気候(BW)"
      }
    }
    else if (minTemp >= 18) { //熱帯である(最寒月平均気温が18℃以上)
      if (minPrec >= 60) { //最少雨月降水量が60mm以上
        return "熱帯雨林気候(Af)"
      }
      else if (100 - 0.04 * sumPrec <= minPrec) {
        return "弱い乾季のある熱帯雨林気候(Am)"
      }
      else {
        return "サバナ気候(Aw)"
      }
    }
    else if (minTemp >= -3) { //温帯である(最寒月平均気温が-3℃以上18℃未満)
      if (type === "s" && minPrec < 30) {//夏季乾燥型かつ最少雨月降水量が30mm未満
        return "地中海性気候(Cs)"
      }
      else if (type === "w") {
        return "温暖冬季少雨気候(Cw)"
      }
      else { //年中湿潤型の判定(Cfa，Cfb)
        if (maxTemp >= 22) {//最暖月平均気温が22℃以上
          return "温暖湿潤気候(Cfa)"
        }
        else {
          return "西岸海洋性気候(Cfb)"
        }
      }
    }
    else if (minTemp < -3) { //亜寒帯である(最寒月平均気温が-3℃未満)
      if (type === "w") {
        return "亜寒帯冬季少雨気候(Dw)"
      }
      else {
        return "亜寒帯湿潤気候(Df)"
      }
    }
    else {
      return "気候区判定不可"
    }
  };

  // 地図上のマーカーをレンダリング
  if (
    selectedLayer.some(layer =>
      ["climate_world", "climate_japan", "climate_world_koppen", "climate_japan_koppen"].includes(layer.id)
    ) && climateData
  ) {
    climates = climateData.flatMap((geoData, dataIndex) => {
      if (!geoData.features) {
        console.error(`GeoJSON data at index ${dataIndex} does not contain features.`);
        return [];
      }

      return geoData.features.map((feature, index) => {
        const chartData = createChartData(
          feature.properties.temperature,
          feature.properties.precipitation
        );
        const coordinates = feature.geometry.coordinates;
        const adjustedLongitude = coordinates[0] >= -25 ? coordinates[0] : coordinates[0] + 360;

        return (
          <FeatureGroup key={`data-${dataIndex}-feature-${index}`} attribution="理科年表">
            <Popup>
              <p>{feature.properties.city} ({feature.properties.country || feature.properties.prefecture})</p>
              <div style={{ width: "250px", height: "250px" }}>
                <Bar data={chartData} options={options} />
              </div>
              <p>ケッペンの気候区分：{koppen(feature.properties.temperature, feature.properties.precipitation, feature.properties.latitude)}</p>
            </Popup>
            <CircleMarker
              center={[coordinates[1], adjustedLongitude]}
              fillColor="#ff7800"
              radius={5}
              color={"#000"}
              weight={0.5}
              opacity={1}
              fillOpacity={0.8}
            />
          </FeatureGroup>
        );
      });
    });
  }

  // latlon の処理
  const LatLon = ({ url, attribution }) => {
    const [latlonData, setLatlonData] = useState(null);

    useEffect(() => {
      const loadGeoJSON = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch GeoJSON data");
          }
          const data = await response.json();
          setLatlonData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSON();
    }, [url]);

    if (!latlonData) return null; // データがまだ読み込まれていない場合は何も表示しない

    // 名前に基づく色を設定する関数
    const getColorByName = (name) => {
      switch (name) {
        case "赤道":
          return "#ff6347"; // 赤
        case "本初子午線":
          return "#4169e1"; // 青
        case "経度180度":
          return "#4169e1"; // 青
        default:
          return "#808080"; // 灰色
      }
    };

    const onEachFeature = (feature, layer) => {
      if (feature.properties && feature.properties.name) {
        layer.bindPopup(feature.properties.name); // 名前があればポップアップを設定
      }
    };

    // スタイル設定
    const style = (feature) => {
      const name = feature.properties?.name || "";
      return {
        color: getColorByName(name), // 境界線の色
        weight: 2, // 線の太さ
      };
    };

    return <GeoJSON data={latlonData} onEachFeature={onEachFeature} style={style} attribution={attribution} />;
  };

  const latlonLayers = selectedLayer
    .filter(slayer => slayer.type === "latlon") // selectedLayer の中から "latlon" タイプのみ抽出
    .map(slayer => (
      <LatLon key={slayer.id} url={slayer.url} attribution={slayer.attribution} />
    ));

  // atmospheric の処理
  const Atmospheric = ({ url, attribution }) => {
    const [atmosphericData, setAtmosphericData] = useState(null);

    useEffect(() => {
      const loadGeoJSON = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch GeoJSON data");
          }
          const data = await response.json();
          setAtmosphericData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSON();
    }, [url]);

    if (!atmosphericData) return null; // データがまだ読み込まれていない場合は何も表示しない

    // 名前に基づく色を設定する関数
    const getColorByName = (name) => {
      switch (name) {
        case "赤道低圧帯（熱帯収束帯）":
          return "#ff6347"; // 赤
        case "亜熱帯高圧帯（中緯度高圧帯）":
          return "#4169e1"; // 青
        case "亜寒帯低圧帯":
          return "#ff6347"; // 赤
        default:
          return "#808080"; // 灰色
      }
    };

    // 名前に基づく説明を設定する関数
    const getDescByName = (name) => {
      switch (name) {
        case "赤道低圧帯（熱帯収束帯）":
          return "赤道付近にあり，貿易風の収束によって形成される。上空5km前後のところに赤道西風が吹く。";
        case "亜熱帯高圧帯（中緯度高圧帯）":
          return "回帰線付近に中心がある高圧帯である。風は弱いが，ここから高緯度側に偏西風が，低緯度側には貿易風が吹き出す。";
        case "亜寒帯低圧帯":
          return "極偏東風と偏西風の収束により，地上付近でジェット気流と対応して形成される。温帯低気圧が発生する。";
        default:
          return "";
      }
    };

    const onEachFeature = (feature, layer) => {
      if (feature.properties && feature.properties.name) {
        layer.bindPopup(feature.properties.name + "<br/>" + getDescByName(feature.properties.name)); // 名前があればポップアップを設定
      }
    };

    // スタイル設定
    const style = (feature) => {
      const name = feature.properties?.name || "";
      return {
        color: getColorByName(name), // 境界線の色
        weight: 2, // 線の太さ
        fillColor: getColorByName(name), // 塗りつぶしの色
        fillOpacity: 0.5, // 塗りつぶしの透明度
      };
    };

    return <GeoJSON data={atmosphericData} onEachFeature={onEachFeature} style={style} attribution={attribution} />;
  };

  const atmosphericLayers = selectedLayer
    .filter(slayer => slayer.type === "atmospheric") // selectedLayer の中から "atmospheric" タイプのみ抽出
    .map(slayer => (
      <Atmospheric key={slayer.id} url={slayer.url} attribution={slayer.attribution} />
    ));


  // current の処理
  const Current = ({ url, attribution }) => {
    const [currentData, setCurrentData] = useState(null);

    useEffect(() => {
      const loadGeoJSON = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch GeoJSON data");
          }
          const data = await response.json();
          setCurrentData(data);
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      };

      loadGeoJSON();
    }, [url]);

    if (!currentData) return null; // データがまだ読み込まれていない場合は何も表示しない

    // 名前に基づく色を設定する関数
    const getColorByType = (type) => {
      switch (type) {
        case "warm":
          return "#ff6347"; // 赤
        case "cold":
          return "#4169e1"; // 青
        default:
          return "#808080"; // 灰色
      }
    };

    const onEachFeature = (feature, layer) => {
      if (feature.properties && feature.properties.name && feature.properties.type === "warm") { // 暖流ならば名前を設定
        layer.bindPopup(feature.properties.name + "（暖流）");
      }
      else if (feature.properties && feature.properties.name && feature.properties.type === "cold") { // 寒流ならば名前を設定
        layer.bindPopup(feature.properties.name + "（寒流）");
      }
    };

    // スタイル設定
    const style = (feature) => {
      const type = feature.properties?.type || "";
      return {
        color: getColorByType(type), // 境界線の色
        weight: 5, // 線の太さ
      };
    };

    return <GeoJSON data={currentData} onEachFeature={onEachFeature} style={style} attribution={attribution} />;
  };

  const currentLayers = selectedLayer
    .filter(slayer => slayer.type === "current") // selectedLayer の中から "current" タイプのみ抽出
    .map(slayer => (
      <Current key={slayer.id} url={slayer.url} attribution={slayer.attribution} />
    ));

  // manhole_distributions の処理
  if (card_list && selectedLayer.some(layer => layer.type === "manhole_distribution")) {
    manhole_distributions = card_list.map((card, cardIndex) => {
      if (card.locations.length) {

        const manholeMarker = () => {
          return Leaflet.icon({
            iconUrl: "/manhole/icon.png",
            iconSize: [25, 25],
            className: "marker",
          });
        };

        const markers = card.locations.map((location, locIndex) => (
          <Marker
            key={`${cardIndex}-${locIndex}`} // ユニークなキー
            position={[
              Number(location.latitude),
              Number(location.longitude)
            ]}
            icon={manholeMarker()}
          >
            <Popup>
              <p style={{ "fontWeight": "bold" }}>{card.card_name}<Badge bg="secondary">{"第" + card.version + "弾"}</Badge></p>
              <p>{location.name}</p>
              <p>{card.open_time}</p>
              <p>{card.stock}</p>
              <img src={"/manhole/" + card.card_image} className="manhole_card" alt={card.card_image} />
            </Popup>
          </Marker>
        ));

        return (
          <FeatureGroup key={cardIndex} attribution="<a href='https://www.gk-p.jp/activity/mc/' target='_blank'>下水道広報プラットフォーム</a>">
            {markers}
          </FeatureGroup>
        );
      }
      return null; // 条件を満たさない場合は null を返す
    }).filter(item => item !== null); // 無効な要素を除外
  }

  return (
    <>
      {rasters}
      {geotiffs}
      {atmosphericLayers}
      {climates}
      {currentLayers}
      {latlonLayers}
      {manhole_distributions}
    </>
  );
};

export default Layer;